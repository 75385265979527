import { LoginPage, LogoutPage, NoAccess, SettingsPage, SignUp, RefreshPage } from '@moveup/app-core/views';

// Route level code-splitting
// This generates a separate chunk for this route
// which is lazy-loaded when the route is visited.
const OnboardingDashboard = () => import('@/views/OnboardingDashboard.vue');
const PatientOverview = () => import('@/views/PatientOverview.vue');
const PatientNew = () => import('@/views/PatientNew.vue');
const PatientEdit = () => import('@/views/PatientEdit.vue');
const PatientList = () => import('@/views/PatientList.vue');
const PatientEditActivity = () => import('@/views/PatientEditActivity.vue');
const PatientCommunication = () => import('@/views/PatientCommunication.vue');

import { checkAuth } from '@moveup/app-core/auth';

const routes = [
	{
		path: '/',
		redirect: '/login',
	},
	{
		path: '/login',
		name: 'Login',
		component: LoginPage,
		props: { startPage: '/dashboard' },
	},
	{
		path: '/logout',
		name: 'Logout',
		component: LogoutPage,
	},
	{
		path: '/no-access',
		name: 'NoAccess',
		component: NoAccess,
		props: { name: 'Patient Onboarding Admin' },
	},
	{
		path: '/refresh',
		name: 'Refresh',
		component: RefreshPage,
	},
	{
		path: '/signup',
		name: 'SignUp',
		component: SignUp,
	},
	{
		path: '/dashboard',
		name: 'OnboardingDashboard',
		component: OnboardingDashboard,
		beforeEnter: checkAuth,
	},
	{
		path: '/patients',
		name: 'Patients',
		component: PatientList,
		beforeEnter: checkAuth,
	},
	{
		path: '/patients/page/:page',
		name: 'PatientsPage',
		component: PatientList,
		beforeEnter: checkAuth,
	},
	{
		path: '/patient/:id',
		name: 'Patient',
		redirect: '/patient/:id/overview',
		beforeEnter: checkAuth,
	},
	{
		path: '/patient/:id/overview',
		name: 'PatientOverview',
		component: PatientOverview,
		beforeEnter: checkAuth,
	},
	{
		path: '/patient/:id/edit',
		name: 'PatientEdit',
		component: PatientEdit,
		beforeEnter: checkAuth,
	},
	{
		path: '/patient/:id/activity',
		name: 'PatientEditActivity',
		component: PatientEditActivity,
		beforeEnter: checkAuth,
	},
	{
		path: '/patient/:id/communication',
		name: 'PatientMail',
		component: PatientCommunication,
		beforeEnter: checkAuth,
	},
	{
		path: '/patient',
		name: 'PatientNew',
		component: PatientNew,
		beforeEnter: checkAuth,
	},
	{
		path: '/settings',
		name: 'Settings',
		component: SettingsPage,
		beforeEnter: checkAuth,
	},
];

export default routes;
