const app = {
	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'moveUP Patient Onboarding',
		// all titles will be injected into this template
		titleTemplate: '%s | moveUP Patient Onboarding',
	},
};

export { app };
