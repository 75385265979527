<template>
	<div>
		<b-navbar spaced wrapper-class="container">
			<template slot="brand">
				<b-navbar-item tag="router-link" :to="{ path: '/dashboard' }">
					<img src="../assets/moveUP-symbol-green.svg" alt="moveUP" />
				</b-navbar-item>
			</template>
			<template v-if="user && user._id" slot="start">
				<b-navbar-item v-translate tag="router-link" :to="{ path: '/dashboard' }">Dashboard</b-navbar-item>
				<b-navbar-item v-translate tag="router-link" :to="{ path: '/patients' }">Patients</b-navbar-item>
			</template>

			<template slot="end">
				<b-navbar-item
					v-translate
					href="https://support.orthopedics.moveup.care/"
					target="_blank"
					rel="noopener"
				>
					Support
				</b-navbar-item>
				<b-navbar-dropdown v-if="user && user._id" :label="$gettext('Account')">
					<b-navbar-item v-translate tag="router-link" :to="{ path: '/settings' }">Settings</b-navbar-item>
					<b-navbar-item v-translate tag="router-link" :to="{ path: '/logout' }">Log out</b-navbar-item>
				</b-navbar-dropdown>
			</template>
		</b-navbar>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	computed: {
		...mapState('auth', ['user']),
	},
};
</script>
